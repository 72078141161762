exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-gameplay-tsx": () => import("./../../../src/pages/gameplay.tsx" /* webpackChunkName: "component---src-pages-gameplay-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nfts-tsx": () => import("./../../../src/pages/nfts.tsx" /* webpackChunkName: "component---src-pages-nfts-tsx" */),
  "component---src-pages-pre-registration-tsx": () => import("./../../../src/pages/pre-registration.tsx" /* webpackChunkName: "component---src-pages-pre-registration-tsx" */),
  "component---src-pages-rewards-tsx": () => import("./../../../src/pages/rewards.tsx" /* webpackChunkName: "component---src-pages-rewards-tsx" */),
  "component---src-pages-roadmap-tsx": () => import("./../../../src/pages/roadmap.tsx" /* webpackChunkName: "component---src-pages-roadmap-tsx" */),
  "component---src-pages-token-tsx": () => import("./../../../src/pages/token.tsx" /* webpackChunkName: "component---src-pages-token-tsx" */),
  "component---src-pages-waitlist-tsx": () => import("./../../../src/pages/waitlist.tsx" /* webpackChunkName: "component---src-pages-waitlist-tsx" */),
  "component---src-pages-world-tsx": () => import("./../../../src/pages/world.tsx" /* webpackChunkName: "component---src-pages-world-tsx" */)
}

